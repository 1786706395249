import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/components/HomeView.vue'
import ThanksView from '@/views/ThanksView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/thanks',
    name: 'thanks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ThanksView,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/privacy-policy/de',
    name: 'privacy-policy-de',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyDEView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "about" */ '../views/404View.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
