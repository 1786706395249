<template>
  <div class="mt-4 grid place-items-center h-screen">
    <div class="grid place-items-center">
      <img
        class="lg:w-1/3 w-1/3 m-0"
        src="@/assets/logo.png"
        alt=""
      />
    </div>
    <div>
      <p class="font-bold text-5xl">jempi.app</p>
      <p>coming soon..</p>
    </div>
    <div class="mt-4">
      <div class="">
        <form
          action="https://formsubmit.co/contact@laurentbrand.com"
          method="POST"
        >
          <div class="mb-6">
            <input type="hidden" name="_next" value="https://jempi.app/thanks" />
            <input type="hidden" name="_captcha" value="false" />
            <input
              v-model="email"
              type="email"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="name@mail.com"
            />
            <input type="text" class="hidden" name="name" :value="email" required>
            
          </div>

          <div>
            <button type="submit">
              <a
                href="#_"
                class="relative px-5 py-3 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
              >
                <span
                  class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"
                ></span>
                <span
                  class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"
                ></span>
                <span
                  class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"
                ></span>
                <span
                  class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"
                ></span>
                <span
                  class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"
                ></span>
                <span
                  class="relative transition-colors duration-300 delay-200 group-hover:text-white ease"
                  >Join Waitlist</span
                >
              </a>
            </button>
          </div>
        </form>
      </div>
    </div>

    <img src="@/assets/mockup.png" class="lg:w-1/2" alt="">
   
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
};
</script>
